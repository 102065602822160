
import { defineComponent, reactive, toRefs, ref } from 'vue'
import CUpload from '@/components/c-upload'
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
import { getToken } from '@/utils/auth'
import { setBaseUrl } from '@/config/url'
import { setAddEnterprise } from './api'
import { rules, economicsStatusMap, economicsTypeMap, customerTypeOpt } from './config'
import { regionData } from 'element-china-area-data'
import moment from 'moment'

export default defineComponent({
  components: { CUpload },
  setup() {
    const data = reactive({
      formData: {
        collectivized: 0,
        customerType: 1,
        systemName: '蓝鲸车管家'
      },
      accountAddressOptions: regionData,
      rules,
      economicsStatusMap,
      economicsTypeMap,
      customerTypeOpt,
      action:
        setBaseUrl().uploadUrl + '/ccclubs-file-center/common/file/uploadFile',
      headers: {
        token: getToken()
      }
    })
    const router = useRouter()
    const formRef = ref<HTMLElement | any>(null)
    const back = () => {
      router.back()
    }
    const uploadBLSuccess = file => {
      ; (data.formData as any).businessLicenseJpg = file.response.data.filename
    }
    const uploadMPSuccess = file => {
      ; (data.formData as any).managePermissionJpg = file.response.data.filename
    }
    const uploadBLRemove = () => {
      if ((data.formData as any).businessLicenseJpg) {
        ; (data.formData as any).businessLicenseJpg = null
      }
    }
    const changeManageTimeStar = o => {
      if (o) {
        ; (data.formData as any).manageTimeStar = moment(
          (data.formData as any).manageTimeStar
        ).format('YYYY-MM-DD')
      }
    }
    const changeManageTimeEnd = o => {
      if (o) {
        ; (data.formData as any).manageTimeEnd = moment(
          (data.formData as any).manageTimeEnd
        ).format('YYYY-MM-DD')
      }
    }
    const changeManageTimeFirst = o => {
      if (o) {
        ; (data.formData as any).manageTimeFirst = moment(
          (data.formData as any).manageTimeFirst
        ).format('YYYY-MM-DD')
      }
    }
    const uploadMPRemove = () => {
      if ((data.formData as any).managePermissionJpg) {
        ; (data.formData as any).managePermissionJpg = null
      }
    }
    const creatSumbit = (): void => {
      formRef.value.validate((valid: boolean) => {
        if (valid) {
          //   const accountProvince = CodeToText[data.accountAddressInfo[0]]
          //   const accountCity = CodeToText[data.accountAddressInfo[1]]
          //   const accountArea = CodeToText[data.accountAddressInfo[2]]
          // data.accountAddressInfo = [accountProvince, accountCity, accountArea]

          setAddEnterprise(data.formData).then(() => {
            ElMessage.success('添加成功')
            back()
          })
        } else {
          ElMessage.warning('表单校验不正确')
        }
      })
    }
    return {
      formRef,
      ...toRefs(data),
      creatSumbit,
      back,
      uploadBLSuccess,
      uploadMPSuccess,
      uploadBLRemove,
      uploadMPRemove,
      changeManageTimeStar,
      changeManageTimeFirst,
      changeManageTimeEnd
    }
  }
})
